window.newMessageCopyCheck = function () {
	if (window._messageCopyCheck) {
		window._messageCopyCheck.bind(this.rootElement)();
	}
}
$(document).ready(function () {
	var targetId = window.location.hash.replace(/^#/, '');
	var target = document.getElementById(targetId);

	if (target) {
		var targetTab = document.querySelector('[data-target="#' + target.id + '"]');
		if (targetTab) {
			targetTab.dispatchEvent(new MouseEvent('click', { bubbles: true, cancellable: true }));
		}
	}
});
$(function () {
	var systemUrls = {
		productSearch: '/products.json',
		categorySearch: '/categories.json',
		customerSearch: '/customers.json',
	};

	var numberInputChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.', ' ', 'ArrowUp', 'ArrowDown', 'Tab'];
	var submitQuoteUpdateDebounced = debounce(submitQuoteUpdate, 1500);
	var submitQuoteUpdateKeyup = debounce(submitQuoteUpdate, 1500);
	var submitQuoteUpdateChange = debounce(submitQuoteUpdate, 1500);

	$('select.input-pack-size').on('change', updatePackPrice);
	$('textarea[name="message[messageCopy]"]').on('change input keyup keydown', messageCopyCheck).each(messageCopyCheck);

	$('form.form-async.form-product').on('submit', submitForm);
	$('form.form-async.form-product').find('[data-item-target], [data-product-target]').on('keydown', inputSubmitProduct).filter('[name^="update"]').on('change', inputChangeProduct);
	$('form.form-async.form-product button').filter('[data-item-target], [data-product-target]').on('click', buttonSubmitProduct);
	$('form.form-async.form-product button[name="quote-action"]').on('click', submitQuoteAction);
	$('input[type="number"][maxlength][name*="[quantityNeeded]"]').on('keydown keyup change input', trimNumberInput);

	$('form.form-async.form-product .product-table').find('[data-item-target], [data-product-target]').not('.input-product-title').filter('[name^="update"]').on('keyup', submitQuoteUpdateDebounced).on('change', submitQuoteUpdateDebounced);
	$('form.form-async.form-product .product-table').find('[data-item-target], [data-product-target]').on('keyup', disableFormAuxiliaryInputs).on('change', disableFormAuxiliaryInputs).each(disableFormAuxiliaryInputs);
	$('form.form-async.form-product .product-table input').filter('[data-item-target], [data-product-target]').on('focus', inputFocusSelect);
	$('form.form-async.form-product .quote-detail-add-product-button').on('click', toggleAddProductInput);

	function updatePackPrice(e) {
		var price = $(this).find('option:selected').attr('data-suggested-price');
		var item = $(this).attr('data-item-target');
		var product = $(this).attr('data-product-target');

		if (item) {
			$('.text-suggested-price[data-item-id="'+item+'"]').text(price ? price : '$0.00');
		} else if (product) {
			$('.text-suggested-price[data-product-id="'+product+'"]').text(price ? price : '$0.00');
		}
	}

	function trimNumberInput(e) {
		var maxlength = parseInt(this.getAttribute('maxlength'), 10);
		if (!isNaN(maxlength) && maxlength > 0 && this.value && this.value.length > maxlength) {
			this.value = this.value.substring(0, maxlength);
		}
	}

	function toggleAddProductInput(e) {
		e.preventDefault()

		$(this).closest('.table-col, .quote-detail-add-product-container').find('.add-product-search-container').each(function(){
			if ($(this).hasClass('active')) {
				$(this).find('#add-product-search').val('');
				$(this).removeClass('active');
			} else {
				$(this).find('#add-product-search').val('').trigger('focus');
				$(this).addClass('active');
			}
		});
	}

	function inputFocusSelect(e) {
		setTimeout(this.select.bind(this), 10);
	}

	var emptyMessageValues = ['<p><br></p>', '<p></p>'];
	function messageCopyCheck() {
		var disabled = this.value.length > 0 ? true : false;
		if (disabled && emptyMessageValues.indexOf(this.value) != -1) {
			disabled = false;
		}
		$('form.form-auxiliary').find('input, select, textarea, button').prop('disabled', disabled);
		$(this).closest('form').find('button').prop('disabled', !disabled);
	}
	window._messageCopyCheck = messageCopyCheck;

	function disableFormAuxiliaryInputs(e) {
		if (!$(this).val()) {
			$(this).addClass('empty');
		} else {
			$(this).removeClass('empty');
		}
		if (!e || e.type == 'keyup' && (!e.originalEvent || !e.originalEvent.key || numberInputChars.indexOf(e.originalEvent.key) < 0)) {
			return;
		} else if (e.type == 'keyup' || e.type == 'change') {
			$('form.form-auxiliary').find('input, select, textarea, button').prop('disabled', true);
		}
	}

	function inputChangeProduct(e) {
		var targetItemId = $(this).attr('data-item-target');
		var targetProductId = $(this).attr('data-product-target');

		if (targetItemId) {
			$(this).closest('form').find('[data-item-target="' + targetItemId + '"]').removeClass('invisible').removeClass('disabled');
		} else {
			$(this).closest('form').find('[data-product-target="' + targetProductId + '"]').removeClass('invisible').removeClass('disabled');
		}
	}

	function inputSubmitProduct(e) {
		if (e.keyCode == 13) {
			e.stopPropagation();
			var doSubmitProduct = submitProduct.bind(this, e);
			doSubmitProduct();
			return false;
		} else {
			return inputChangeProduct.bind(this)(e);
		}
	}

	function buttonSubmitProduct(e) {
		e.stopPropagation();

		if (this.classList.contains('quote-delete-button') && !this.value) {
			var form = this.closest('form');

			var targetItemId = $(this).attr('data-item-target');
			var targetProductId = $(this).attr('data-product-target');
			var targetProductType = $(this).attr('data-product-type');

			if (targetItemId) {
				$(form).find('[name^="update"][data-item-target="' + targetItemId + '"][name*="quantityNeeded"].input-qty-needed').val(0);
			} else {
				$(form).find('[name^="update"][data-product-target="' + targetProductId + '"][name*="quantityNeeded"].input-qty-needed').val(0);
			}
		}

		var doSubmitProduct = submitProduct.bind(this, e);
		doSubmitProduct();
		return false;
	}

	function submitQuoteUpdate(e) {
		e.stopPropagation();

		if (e.type == 'keyup' && (!e.originalEvent || !e.originalEvent.key || numberInputChars.indexOf(e.originalEvent.key) < 0)) {
			console.log(e.type, e.originalEvent.key);
			return;
		}

		var form = this.closest('form');

		$(form).find('[data-product-type="addon"]').prop('disabled', true);

		var actionUrl = $(form).attr('action');
		var data = $(form).serialize();

		return processFormSubmission(form, data, actionUrl, true);
	}

	function submitQuoteAction(e) {
		e.stopPropagation();

		var form = this.closest('form');
		var actionUrl = $(form).attr('action');
		var data = $(form).serialize();
		data += '&' + $('<input/>', { name: $(this).attr('name'), value: $(this).val() }).serialize();

		return processFormSubmission(form, data, actionUrl);
	}

	function submitProduct(e) {
		var form = this.closest('form');
		var itemRoot = this.closest('.table-col-addons');
		var targetItemId = $(this).attr('data-item-target');
		var targetProductId = $(this).attr('data-product-target');
		var targetCategoryId = $(this).attr('data-category-target');

		var itemFieldSelector = targetItemId ? '[data-item-target="' + targetItemId + '"]' : '[data-product-target="' + targetProductId + '"]';

		if (targetCategoryId) {
			itemFieldSelector += '[data-category-target="' + targetCategoryId + '"]';
		}

		if (!itemRoot) {
			itemRoot = form;
		}

		var itemData = $(itemRoot).find(itemFieldSelector).serialize();
		var data = $(form).find('input[name="action"],input[name="redirect"],input[name="CRAFT_CSRF_TOKEN"],input[name="orderId"]').serialize();
		var actionUrl = $(form).attr('action');

		if (itemData.length > 0) {
			data += '&' + itemData;
		}

		if (e.currentTarget.tagName == 'BUTTON' && e.currentTarget.value) {
			var button = e.currentTarget;
			data += '&' + $('<input/>', { name: $(button).attr('name'), value: $(button).val() }).serialize();
			data += '&' + $('<input/>', { name: 'forceReturnUrl', value: 1 }).serialize();
		}

		return processFormSubmission(form, data, actionUrl);
	}

	function submitForm(e) {
		e.preventDefault();

		if (this.classList.contains('form-bypass')) {
			return false;
		}

		var form = this;
		var data = $(form).serialize();
		var actionUrl = $(form).attr('action');

		return processFormSubmission(form, data, actionUrl);
	}

	function processFormSubmission(form, data, actionUrl, noSpinnerOverlay) {
		// console.log(form, data, actionUrl);

		var $spinner = $('.spinner-overlay.page-spinner');
		var $body = $('body');

		if (noSpinnerOverlay) {
			$body.addClass('sync-update');
		} else {
			$spinner.addClass('d-flex').removeClass('d-none');
		}

		$.ajax({
			method: 'POST',
			url: actionUrl,
			data: data,
			dataType: 'json',
			contentType: 'application/x-www-form-urlencoded',
			success: function (data) {
				if (data.error) {
					$(form).find('.order-error').removeClass('d-none').text(data.error);
				} else {
					$(form).find('.order-error').addClass('d-none').text('');
				}

				if (console && console.log) {
					console.log('success', data);
				}

				if (data.forceReturnUrl && data.returnUrl) {
					//NOTE: refresh required to reflect all quote updates
					window.location.href = data.returnUrl;
					// alert('RELOAD!');
				} else if (data.orderSummary && data.orderSummary.items) {
					for (var i in data.orderSummary.items) {
						var item = data.orderSummary.items[i];
						var $updateButton = $(form).find('button[data-item-target="' + item.itemId + '"].quote-update-button');

						var quantityNeeded = $(form).find('[name^="update"][data-item-target="' + item.itemId + '"][name*="quantityNeeded"].input-qty-needed').val();
						var itemStatus = $(form).find('[name^="update"][data-item-target="' + item.itemId + '"][name*="itemStatus"]').val();
						var quantityNeededUnit = $(form).find('[name^="update"][data-item-target="' + item.itemId + '"][name*="quantityNeededUnit"]:not(.input-qty-needed)').val();
						var packageSizeRequested = $(form).find('[name^="update"][data-item-target="' + item.itemId + '"][name*="packageSizeRequested"]').val();

						// console.log($updateButton.length, quantityNeeded == item.quantityNeeded, itemStatus == item.itemStatus, quantityNeededUnit == item.quantityNeededUnit, packageSizeRequested == item.packageSizeRequested);
						if ($updateButton.length && quantityNeeded == item.quantityNeeded && itemStatus == item.itemStatus && quantityNeededUnit == item.quantityNeededUnit && packageSizeRequested == item.packageSizeRequested) {
							$updateButton.addClass('invisible').addClass('disabled');
							if (item.quantityNeeded == 0) {
								$(form).find('[data-item-target="' + item.itemId + '"].table-col').remove();
							}
						} else if ($updateButton.length) {
							$updateButton.removeClass('invisible').removeClass('disabled');
						}
					}
					for (var i = 0, len = data.deletedItems.length; i < len; i++) {
						$(form).find('[data-item-target="' + data.deletedItems[i] + '"].table-col').remove();
					}
					for (var property in data.orderSummary) {
						if (property == 'orderStatus') {
							var statusSlug = data.orderSummary[property] ? data.orderSummary[property].slug : 'unknown';
							$('.quote-status-chip').each(function(){
								this.className = 'quote-status-chip quote-status-chip-' + statusSlug;
								$(this).find('.quote-status-title').text(data.orderSummary[property] ? data.orderSummary[property].title : 'Unknown');
								$(this).find('.fac:not(.fac-circle').each(function(){
									this.className = 'fac fac-order-' + statusSlug + ' fac-stack-1x';
								});
							});
							$('[data-order-status]').attr('data-order-status', statusSlug);
						} else if (typeof data.orderSummary[property] === 'number' && property.match(/(Price|Total)$/)) {
							var value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.orderSummary[property]);
							// console.log(property, value);
							$('[data-order-summary-property="' + property + '"]').text(value);
						} else if (typeof data.orderSummary[property] === 'number' || typeof data.orderSummary[property] === 'string') {
							$('[data-order-summary-property="' + property + '"]').text(data.orderSummary[property]);
						}
					}

					// console.log(data.orderSummary.itemCount);

					if (data.orderSummary.itemCount && !isNaN(data.orderSummary.itemCount)) {
						$('.quote-customer-detail-button .badge-pill').text(data.orderSummary.itemCount);
						$('[data-order-item-count]').attr('data-order-item-count', data.orderSummary.itemCount);
					} else {
						$('.quote-detail-send-button').prop('disabled', true);
					}
					$('input, select, textarea, button').not('#add-product-search, .input-product-title, .input-quote-price, .input-substituted-reason').filter(':focus').trigger('blur');
				} else {
					// window.location.reload();
				}
			},
			error: function (data) {
				$(form).find('.order-error').removeClass('d-none').text('An unknown error occurred!');
				if (console && console.log) {
					console.log('error', data);
				}
			},
			complete: function (data) {
				$spinner.addClass('d-none').removeClass('d-flex');
				$body.removeClass('sync-update').removeClass('sync-update');
				$('form.form-auxiliary').find('input, select, textarea, button').prop('disabled', false);
				$(form).find('[data-product-type="addon"]').prop('disabled', false);

				if (data.csrfTokenValue) {
					$(form).find('input[name="CRAFT_CSRF_TOKEN"]').val(data.csrfTokenValue);
				}

				if (console && console.log) {
					// console.log('complete', data);
				}
			}
		});
	}

	$('.add-product-search-container #add-product-search').each(initAddProductAutocomplete);
	$('.product-table .input-product-title').each(initSubstituteAutocomplete);
	$('#quote-filter-product, .product-search-input').each(initProductAutocomplete);
	$('#quote-filter-product-category').each(initCategoryAutocomplete);
	$('#quote-filter-buyer, #buyer-filter-buyer').each(initCustomerAutocomplete);

	function initAddProductAutocomplete() {
		var input = this;
		var parent = input.closest('.add-product-search-container');
		$(input).on('blur', function(e) {
			setTimeout(function(){
				$(input).val();
				$(parent).removeClass('active');
			}, 333);
		});
		$(input).autocomplete({
			serviceUrl: systemUrls.productSearch,
			appendTo: $(parent),
			onSelect: function (suggestion) {
				var form = input.closest('form');
				var actionUrl = $(form).attr('action');

				// console.log(suggestion);
				var data = $(form).find('input[name="action"],input[name="redirect"],input[name="CRAFT_CSRF_TOKEN"],input[name="orderId"]').serialize();
				data += '&' + $('<input/>', { name: 'update[' + suggestion.id + '][itemProduct][]', value: suggestion.id }).serialize();
				data += '&' + $('<input/>', { name: 'update[' + suggestion.id + '][quantityNeeded]', value: 1 }).serialize();
				data += '&' + $('<input/>', { name: 'update[' + suggestion.id + '][itemStatus]', value: 'requested' }).serialize();
				data += '&' + $('<input/>', { name: 'forceReturnUrl', value: 1 }).serialize();

				return processFormSubmission(form, data, actionUrl);
			},
			transformResult: function (response) {
				return {
					suggestions: $.map(JSON.parse(response).data, function (dataItem) {
						return { value: dataItem.title, url: dataItem.url, id: dataItem.id };
					})
				};
			}
		});
	}

	function initSubstituteAutocomplete() {
		var input = this;
		var $tableCol = $(input).closest('.table-col');
		var $productIdInput = $tableCol.find('input.input-product-id');
		var initValue = input.value;

		$(input).on('blur', function(e) {
			input.value = initValue;
			if (initValue && initValue.length) {
				$(this).removeClass('empty');
			}
		});

		$(input).autocomplete({
			serviceUrl: systemUrls.productSearch,
			appendTo: $tableCol,
			onSelect: function (suggestion) {
				var form = input.closest('form');
				var actionUrl = $(form).attr('action');
				var targetItemId = $(input).attr('data-item-target');
				var targetProductId = $(input).attr('data-product-target');

				if (suggestion.id != $productIdInput.val()) {
					$productIdInput.val(suggestion.id);
					var itemFieldSelector = targetItemId ? '[data-item-target="' + targetItemId + '"]' : '[data-product-target="' + targetProductId + '"]';
					var data = $(form).find('input[name="action"],input[name="redirect"],input[name="CRAFT_CSRF_TOKEN"],input[name="orderId"],' + itemFieldSelector).serialize();
					data += '&' + $('<input/>', { name: 'forceReturnUrl', value: 1 }).serialize();

					return processFormSubmission(form, data, actionUrl);
				}
			},
			transformResult: function (response) {
				return {
					suggestions: $.map(JSON.parse(response).data, function (dataItem) {
						return { value: dataItem.title, url: dataItem.url, id: dataItem.id };
					})
				};
			}
		});
	}

	function initProductAutocomplete() {
		initAutocomplete.bind(this)(systemUrls.productSearch);
	}

	function initCategoryAutocomplete() {
		initAutocomplete.bind(this)(systemUrls.categorySearch);
	}

	function initCustomerAutocomplete() {
		initAutocomplete.bind(this)(systemUrls.customerSearch);
	}

	function initAutocomplete(searchUrl) {
		var input = this;
		var targetInputId = input.getAttribute('data-target-input');
		var targetInput = targetInputId ? document.getElementById(targetInputId) : null;
		console.log(searchUrl);

		$(input).autocomplete({
			serviceUrl: searchUrl,
			appendTo: $(input).closest('.product-search-bar-container, .autocomplete-container'),
			onSelect: function (suggestion) {
				if (targetInput) {
					targetInput.value = suggestion.id
				} else if (suggestion.url) {
					window.location.href = suggestion.url;
				}
			},
			transformResult: function (response) {
				return {
					suggestions: $.map(JSON.parse(response).data, function (dataItem) {
						return { value: dataItem.title, url: dataItem.url, id: dataItem.id };
					})
				};
			}
		});
	}

	// Jquery Dependency
	$('.product-table input[data-type="currency"].width-auto').on('keyup blur', formatCurrencyEvent).each(resizeInput);
	$('.product-table input[type="number"].width-auto').on('keyup blur', resizeInput).each(resizeInput);

	function formatNumber(n) {
		// format number 1000000 to 1,234,567
		return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	function formatCurrencyEvent(e) {
		formatCurrency($(this), e.type == 'blur');
		resizeInput.bind(this)(e);
	}

	function resizeInput(e) {
		var len = this.value.length ? this.value.length + 0.5 : 3;
		this.style.width = 'calc(' + len + 'ch + 4px)';
	}

	function formatCurrency(input, blur) {
		var input_val = input.val();
		var reset_caret = (input[0].selectionStart == input[0].selectionEnd);

		// don't validate empty input
		if (input_val === "") { return; }

		// original length
		var original_len = input_val.length;

		// initial caret position
		var caret_pos = input.prop("selectionStart");

		// check for decimal
		if (input_val.indexOf(".") >= 0) {

			// get position of first decimal
			// this prevents multiple decimals from
			// being entered
			var decimal_pos = input_val.indexOf(".");

			// split number by decimal point
			var left_side = input_val.substring(0, decimal_pos);
			var right_side = input_val.substring(decimal_pos);

			// add commas to left side of number
			left_side = formatNumber(left_side);

			// validate right side
			right_side = formatNumber(right_side);

			// On blur make sure 2 numbers after decimal
			if (blur) {
				right_side += "00";
			}

			// Limit decimal to only 2 digits
			right_side = right_side.substring(0, 2);

			// join number by .
			input_val = "$" + left_side + "." + right_side;
			input_val = left_side + "." + right_side;

		} else {
			// no decimal entered
			// add commas to number
			// remove all non-digits
			input_val = formatNumber(input_val);
			// input_val = "$" + input_val;

			// final formatting
			if (blur) {
				input_val += ".00";
			}
		}

		// send updated string to input
		input.val(input_val);

		if (!blur && reset_caret) {
			// put caret back in the right position
			var updated_len = input_val.length;
			caret_pos = updated_len - original_len + caret_pos;
			input[0].setSelectionRange(caret_pos, caret_pos);
		}
	}
});

